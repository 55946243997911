@import "./common.scss";

$spacing: 8px;
$navbarHeight: 48px;
$logoHeight: 70px;
$primaryColor: #00cfb5;

$primaryFontColor: #00184e;
$secondaryFontColor: #001e62;
$grayFontColor: #6678a1;

$backgroundAlarmColor: #fff;
$secondaryAlarmFontColor: #fff;

$secondaryColor: #001e62;

$borderColor: #bcc3c8;
$buttonTextColor: #fff;

$gradientColor: $secondaryColor;

$alarmColor: #e56174;

$errorColor: #ff4531;

$activeMenuColor: #cad8fe;
$tabColor: $primaryColor;

$primaryBackgroundColor: $primaryColor;
$secondaryBackgroundColor: #e5e5e5;
$menuBackgroundColor: #ffffff;
$menuColor: #88969f;
$menuBorderTop: none;

$chartFontColor: #88959e;
$chartAxisColor: #88959e;
$chartLineOrBarColor: $secondaryColor;
$chartAdditionalLineOrBarColor: $primaryColor;

$deviceDataFontColor: $primaryFontColor;

$secondaryButtonColor: gray;

$fontFamily: "SourceSansPro-Regular";
$fontSize: 16px;

%defaultFont {
    font-family: $fontFamily;
    font-size: $fontSize;
}
