@mixin single-arrow($rotation) {
	transform: rotate($rotation);

	* {
		fill: $secondaryColor;
	}
}

@mixin double-arrow($rotation, $top, $left) {
	position: relative;
	transform: rotate($rotation) translate($left, $top);

	svg:first-child {
		transform: translateX(-5px);
	}

	svg:nth-child(2) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		transform: translateX(5px);
	}

	* {
		fill: $secondaryColor;
	}
}
