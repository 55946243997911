@import "~variables.scss";

.ForgotPassword-container {
	padding: 18px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	font-family: $fontFamily;
}

.ForgotPassword-logo {
	display: block;
	height: 200px;
	width: 60%;
	flex-shrink: 3;
}

.ForgotPassword-inputs {
	flex-grow: 1;
}

.ForgotPassword-form {
	padding: 20px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	box-sizing: border-box;
}

.ForgotPassword-error {
	color: $errorColor;
	padding: 20px 0;
	font-size: 1.2em;
	text-align: center;
}

.ForgotPassword-redirect {
	color: $secondaryFontColor;
	text-decoration: underline;
}