$switchbarPadding: 4px;

.switchbar {
	background-color: $primaryColor;
	color: #012e77;
	&--temp {
		background-color: white;
		border: 2px solid white;
		border-radius: 8px 0 0 8px;
		display: inline-flex;
		padding: $switchbarPadding;
	}
	&--humid {
		background-color: white;
		border: 2px solid white;
		border-radius: 0 8px 8px 0;
		display: inline-flex;
		padding: $switchbarPadding;
	}

	&--is-active {
		background-color: #c2dee3;
		border: 2px solid #c2dee3;
	}
}