@import '~variables.scss';

.AddGateway-loaderContainer {
    justify-content: center;
}

.AddGateway-loaderHeader {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AddGateway-loaderParagraph {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AddGateway-instruction {
    > .st0 {
        fill: $secondaryColor !important;
    }
    > * .st0 {
        fill: $secondaryColor !important;
    }
}

.AddGateway-connectGatewayParagraph {
    padding: 10px 0;
}

.AddGateway-whereIsCodeParagraph {
    padding-bottom: 10px;
}

.AddGateway-whereFindACode {
    > .st3 {
        fill: $secondaryColor !important;
    }
    > * .st3 {
        fill: $secondaryColor !important;
    }
}

.AddGateway-plugIcon {
    @extend .AddGateway-instruction;
    max-height: 30vh;
}
