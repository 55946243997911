@import '~variables.scss';

.TermsOfUse-content {
    overflow: scroll;
    text-align: left;

    > p {
        padding: 16px 0px;
    }
}
