.AddMeterInstruction-container {
    display: flex;

    * {
        flex-grow: 4;
    }
}

.AddMeterInstruction-tick {
    flex-grow: 1;
    margin-right: 20px;
}
