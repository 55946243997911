.gateway {
	padding: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	&--checkNetworksButton {
		color: white !important;
		background-color: #007AC9 !important;
		margin-top: 10%;
	}
	&--confirmInstructionButton {
		color: white !important;
		background-color: #007AC9 !important;
		margin-top: 10%;
		//width: 50% !important;
		//align-self: center;
	}
	&--password {
		form {
			margin-top: 10%;
		}

		&Button {
			margin-top: 20%;
		}
	}
	&--logo {
		height: 60px;
		margin: 0 auto;
	}
}