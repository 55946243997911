@import "~variables.scss";
@import "mixins";

@font-face {
	font-family: 'SourceSansPro-Regular';
	src: url('../static/SourceSansPro-Regular.ttf');
  }
  
  @font-face {
	font-family: 'SourceSansPro-Bold';
	src: url('../static/SourceSansPro-Bold.ttf');
  }
  
  @font-face {
	font-family: 'SourceSansPro-Light';
	src: url('../static/SourceSansPro-Light.ttf');
  }

@font-face {
	font-family: 'Roboto';
	src: url('../static/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Comfortaa';
    src: url('../static/Comfortaa-Regular.ttf')
}

@font-face {
    font-family: 'Comfortaa-Bold';
    src: url('../static/Comfortaa-Bold.ttf')
}

@font-face {
    font-family: 'Titillium';
    src: url('../static/TitilliumWeb-Regular.ttf')
}

#root {
	height: 100%;
}

html, body {
	margin: 0;
	font-size: 16px;
	height: 100%;
	font-family: $fontFamily;
	color: $primaryFontColor
}

p {
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
}

a:-webkit-any-link {
	text-decoration: none;
}

@import "components/devices";
@import "components/gateway";
@import "components/registration";
@import "components/meter";
@import "components/helpers";
@import "components/calendar";
@import "components/details";
@import "components/switchbar";