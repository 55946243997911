@import '~variables.scss';

$circleSize: 25px;

.Circle-container {
    background-color: $secondaryColor;
    color: $secondaryAlarmFontColor;
    width: $circleSize;
    height: $circleSize;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 0.8em;
}
