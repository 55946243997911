@import '~variables.scss';

.AddMeter-successLogo {
	flex-shrink: 1;
	max-height: 200px;
	* {
		fill: $secondaryColor !important;
	}
}

.AddMeter-successContent {
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	align-items: center;
}

.AddMeter-instructionBreak {
	border: 1px solid $borderColor;
}

.AddMeter-pigMeter {
	position: relative;
}

.AddMeter-pig {
	top: 58%;
	right: 35%;
	transform: translate(50%, -50%);
	width: 45%;
	position: absolute;
}

.AddMeter-meterAttachment {
	max-height: 200px;
}

.AddMeter-meterAttachmentMagnetic {
	circle {
		fill: #ffea00;
	}
}

.AddMeter-magneticInstructionParagraph {
	padding: 20px 0;
}

.AddMeter-magneticInstruction {
	width: 70%;
	align-self: center;
}

.AddMeter-initialValuesForm {
	padding-top: 15px;
}

.WhereIsMeterCode-subheader {
	padding: 30px 0;
}

.WhereIsMeterCode-image {
	width: 80%;
	align-self: center;
}

.WhereIsMeterCode-paragraph {
	@extend .WhereIsMeterCode-image;
	padding: 20px 0;
}

.WhereIsMeterCode-list {
	@extend .WhereIsMeterCode-image;
	width: 90%;
	text-align: left;
	margin: 0;
	padding: 0;
}

.AddMeter-meterTypeIcon {
	height: 2.5em;
	width: 2.5em;
	* {
		fill: $secondaryAlarmFontColor !important;
	}
}

.AddMeter-initialValuesHeader {
	padding-bottom: 15px;
}

.AddMeter-initialValueInstructionIcon {
	height: 100%;
	max-height: 400px;
	.st0 {
		stroke: $secondaryColor !important;
	}

	.st13 {
		fill: $secondaryColor !important;
	}
}

%AddMeter-instructionCircle {
	position: absolute;
}

.AddMeter-firstInstructionCircle {
	@extend %AddMeter-instructionCircle;
	top: 5%;
	left: 15%;
}

.AddMeter-secondInstructionCircle {
	@extend %AddMeter-instructionCircle;
	bottom: 4%;
	left: 35%;
}

.AddMeter-connectGatewayButton {
	margin-bottom: 20px;
	display: inline-block;
}

.AddMeter-infographic {
    > .st0 {
        fill: $secondaryColor !important;
    }
    > * .st0 {
        fill: $secondaryColor !important;
    }
}
