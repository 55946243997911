.devices {
	&--item {
		margin: 12px 0;
		text-align: initial;
		//border-bottom: 1px solid grey;
	}

	&--header {
		color: rgb(0, 44, 119);
		font-weight: 700;
		font-size: 1.4rem;
		margin-bottom: 6px;
	}

	&--subheading {
		color: rgb(113, 113, 113);
		font-size: 1.1rem;
		font-weight: 400;
		opacity: .8;
	}

	&--disabled {
		opacity: .4;
	}

	&--offline {
		margin-right: 12px;
	}
	&--online {
		margin-right: 12px;
	}

    &--alarm {
		color: darkred !important;
		font-size: 1rem !important;
	}
}