@import "~variables.scss";

.TechnicalBreak {
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    text-align: center;
  }
  & .header {
    padding: 40px 0 20px 0;
    font-size: 1.2em;
  }
  & .content {
    padding: 5px;
  }
  & .logo {
    height: 80px;
  }
}
